<template>
  <p-container>
    <h3>{{ $t('page.legalService.title.sendLegalEnquiry') }}</h3>
    <p-row column>
      <p-col>
        <p-alert type="success" :value="true">
          {{ $t('page.legalService.text.legalEnquirySentSuccess') }}
        </p-alert>
      </p-col>
      <p-col>
        <p-button @click="goBack">
          {{ $t('app.back') }}
        </p-button>
      </p-col>
    </p-row>
  </p-container>
</template>

<script>
  export default {
    methods: {
      goBack() {
        return this.$router.back();
      },
    },
  };
</script>
